<template>
  <vuestic-widget>
    <template slot="header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <span class="title">Tags</span>
        <div class="d-flex justify-content-end align-items-center">
          <button type="button" class="btn btn-primary btn-sm" @click="openModalCreate">Add New Tag <i
              class="fa fa-plus"></i></button>
        </div>
      </div>
    </template>
    <div class="row">
      <div class="col-md-12">
        <div class="d-flex justify-content-center" v-if="tagFetching">
          <span class="atom-spinner-wrapper">
            <atom-spinner slot="loading" :animation-duration="1500" :size="120" color="rgb(53,120,198)" />
          </span>
        </div>
        <div v-else>
          <datatable v-bind="this.tableConfig" class="le-datatable" />
        </div>
      </div>
    </div>
    <vuestic-modal :isOpen="isOpenModalEdit" @cancel="closeModalEdit" :cancelShown="false" :okShown="false">
      <span slot="title">{{ isEdit ? 'Edit Tag' : 'Create New Tag' }}</span>
      <ValidationObserver v-slot="{ handleSubmit, invalid, dirty }">
        <form @submit.prevent="handleSubmit(onFormSubmit)">
          <div class="row">
            <div class="col-md-12">
              <p class="text-danger">The default keywords below are restricted and reserved for the system you can not use
                any of these.</p>
              <p class="restricted-keyword-list">
                <span class="badge badge-primary">stop</span>
                <span class="badge badge-primary">stopall</span>
                <span class="badge badge-primary">unsubscribe</span>
                <span class="badge badge-primary">cancel</span>
                <span class="badge badge-primary">end</span>
                <span class="badge badge-primary">start</span>
                <span class="badge badge-primary">unstop</span>
                <span class="badge badge-primary">delete</span>
              </p>
            </div>
            <div class="col-md-12">
              <text-input name="name" v-model="submitData.name" label="Tag Name" :validate="'required'" />
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12 d-flex justify-content-center">
              <button type="button" class="btn btn-danger mr-2" @click="closeModalEdit">Cancel</button>
              <button class="btn btn-primary" :disabled="tagProcessing || invalid" style="min-width: 80px;">
                <atom-spinner v-if="tagProcessing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                  style="margin: auto;" />
                <span v-else>{{ !isEdit ? 'Create' : 'Update' }}</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vuestic-modal>
    <vuestic-modal :isOpen="isOpenModalDelete" :hideDefaultActions="true" @cancel="closeModalDelete" okText="Delete"
      :cancelShown="false" @ok="onDeleteTag" okClass="btn btn-danger btn-primary">
      <span slot="title" class="text-danger">Delete Tag</span>
      <p v-if="tableConfig.selection.length > 1">Are you sure you want to delete selected tags? </p>
      <p v-else>Are you sure you want to delete this Tag <strong>{{ submitData ? submitData.name : '' }}</strong> ?</p>
    </vuestic-modal>
  </vuestic-widget>
</template>

<script>
import Vue from 'vue';
import components from "../../common/tables/comps";
import Action from './Actions/Action'
import { mapState } from 'vuex';

export default {
  components: {},
  data() {
    return {
      isEdit: false,
      loading: false,
      isOpenModalEdit: false,
      isOpenModalDelete: false,
      tableConfig: {
        supportBackup: false,
        supportNested: false,
        HeaderSettings: false,
        tblClass: "table-bordered",
        tblStyle: "color: #666",
        pageSizeOptions: [10, 25, 50, 100],
        columns: (() => {
          return [
            { title: "ID", field: "id", label: "ID", tdClass: "center", sortable: false, visible: true, },
            { title: "Tag Name", field: "name", label: "Tag Name", tdClass: "center", sortable: false, visible: true },
            { title: "Tagged Contacts #", field: "contacts_no", label: "Tagged Contacts #", tdComp: 'TaggedContactCount', tdClass: "center", sortable: false, visible: true },
            { title: '', tdComp: Action, visible: 'true', tdClass: 'center', },
          ];
        })(),
        data: [],
        selection: [],
        total: 0,
        // selection: [],  //if this is present the row selector shows up..
        summary: {},
        query: {},
        // any other staff that you want to pass to dynamic components (thComp / tdComp / nested components)
        xprops: {
          eventbus: new Vue()
        }
      },
      submitDataOrg: {
        id: null,
        name: ''
      },
      submitData: {
        id: null,
        name: ''
      }
    };
  },

  created() {
    this.submitData = Vue.util.extend({}, this.submitDataOrg)
    this.tableConfig.xprops.eventbus.$on('openEditModal', row => {
      this.isEdit = true
      this.submitData = {
        id: row.id,
        name: row.name
      }

      this.isOpenModalEdit = true
    })

    this.tableConfig.xprops.eventbus.$on('openDeleteModal', row => {
      this.submitData = {
        id: row.id,
        name: row.name
      }
      this.isOpenModalDelete = true
    })

    this.tableConfig.xprops.eventbus.$on('onLinkClick', row => {
      this.$router.push({ name: 'business.contacts', query: { tag: row.id } })
    })

    const offset = (this.tagPage - 1 >= 0 ? this.tagPage - 1 : 0) * this.tagPerPage;
    this.tableConfig.query = {
      offset,
      limit: this.tagPerPage,
    }
  },

  watch: {
    tags: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.tableConfig.data = newValue;
        }
      },
      deep: true,
    },
    tagsTotal: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.tableConfig.total = newValue;
        }
      },
      deep: true,
    },
    'tableConfig.query': {
      handler() {
        this.loadTags();
      },
      deep: true
    }
  },

  computed: {
    ...mapState('tag', {
      tagFetching: 'fetching',
      tagDidFetch: 'didFetch',
      tagProcessing: 'processing',
      tagPage: 'page',
      tagPerPage: 'perPage',
      tagFilter: 'filter',
      tags: 'data',
      tagsTotal: 'total'
    })
  },
  mounted() {

  },
  methods: {
    loadTags() {
      const { query } = this.tableConfig;
      const page = query.offset ? Math.floor(query.offset / query.limit) + 1 : 1;
      const param = {
        page,
        per_page: query.limit,
        order: query.order,
        sort: query.sort
      }

      this.$store.dispatch('tag/fetch', param)
    },

    onFormSubmit() {
      if (this.isEdit) {
        const param = {
          id: this.submitData.id,
          data: this.submitData
        }
        this.$store
          .dispatch('tag/update', param)
          .then(() => {
            this.isOpenModalEdit = false;
            this.loadTags();
          })
          .catch((err) => {
          })
      } else {
        const param = {
          name: this.submitData.name
        }
        this.$store
          .dispatch('tag/create', param)
          .then(() => {
            this.isOpenModalEdit = false;
            this.loadTags();
          })
          .catch((err) => {
          })
      }
    },

    openModalCreate() {
      this.submitData = Vue.util.extend({}, this.submitDataOrg)
      this.isEdit = false
      this.isOpenModalEdit = true
    },

    closeModalEdit() {
      this.isOpenModalEdit = false
    },

    closeModalDelete() {
      this.isOpenModalDelete = false
    },

    onDeleteTag() {
      let params = {
        ids: this.tableConfig.selection.length ? this.tableConfig.selection.map(item => item.id) : [this.submitData.id]
      }
      this.$store
        .dispatch('tag/deleteBulk', params)
        .then(() => {
          this.isOpenModalDelete = false;
          this.loadTags();
        })
        .catch((err) => {
        })
    },
  },

};
</script>

<style lang="scss" scoped>
.restricted-keyword-list {
  span {
    display: inline-block;
    margin-right: 5px;
  }
}
</style>
